import { ConnectionType, MeetingConnection, MeetingDetailsType } from '../api';

export class Connection {
  public static create(meetingDetails: Partial<MeetingDetailsType>): Connection | null {
    if (!meetingDetails?.connection) return null;

    return new Connection(meetingDetails.connection);
  }

  private static get regularConnectionTypeList(): ConnectionType[] {
    return ['ZOOM', 'WEBEX', 'TEAMS', 'BLUEJEANS', 'OTHER', 'UNIQUE_JOINING_LINK', 'VERACAST'];
  }

  public isKnovioConnectionType(): boolean {
    return this.type === 'KNOVIO';
  }

  public isShowcaseConnectionType(): boolean {
    return this.type === 'SHOWCASE_PAGE_PATH';
  }

  public isWebinarConnectionType(): boolean {
    return this.type === 'WEBINAR';
  }

  public isInPersonConnectionType(): boolean {
    return this.type === 'IN_PERSON';
  }

  public isRegularConnectionType(): boolean {
    return Connection.regularConnectionTypeList.includes(this.type);
  }
  public get type(): ConnectionType {
    return this.props.type;
  }

  public get meetingLocation(): string {
    return this.props.meetingLocation ?? '';
  }
  public get embedCodeId(): string {
    return this.props.embedCodeId ?? '';
  }
  public get connectionLink(): string {
    return this.props.connectionLink ?? '';
  }

  public get meetingInvitation(): string {
    return this.props.meetingInvitation ?? '';
  }

  public get showcasePagePath(): string {
    return this.props.showcasePagePath ?? '';
  }

  public get zoomSyncEnabled(): boolean {
    return !!this.props.zoomSyncEnabled;
  }

  public get detailsPlaceholderText(): string {
    return this.props.detailsPlaceholderText ?? '';
  }

  public get isKnovioAvailable(): boolean {
    return this.isKnovioConnectionType() && !!this.props.publishEnabled;
  }

  private constructor(props: MeetingConnection) {
    this.props = props;
  }

  private readonly props: MeetingConnection;
}
