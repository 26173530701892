import React, { useContext } from 'react';
import useConnectToPrivateMeetingMethod from '../../../hooks/use-connect-to-private-meeting-method';
import { PrivateMeetingDetailsResponseType } from '../../../lib/api';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import JoinButton from '../../shared/JoinButton';
import { JoinUrlContext } from '../../shared/MeetingDetails';
import { Connection } from '../../../lib/connection/connection';

export const SHOWCASE_PAGE_PATH_CONNECTION_INVALID_TEXT = 'No connection to the meeting is available, please contact the event administrator';
export const ZOOM_REGISTRATION_IN_PROGRESS_TEXT = 'One moment while we get everything ready for you to connect';

export type ConnectToMeetingProps = { isConnectBtnDisabled: boolean } & PrivateMeetingDetailsResponseType;

const ConnectToMeeting: React.FunctionComponent<ConnectToMeetingProps> = (props: ConnectToMeetingProps): JSX.Element => {
  const eventSettings = useEventSettings() as EventSettingsContextType;
  const { isConnectBtnDisabled, ...meetingDetails } = props;
  const joinUrl = useContext(JoinUrlContext);

  const connection = Connection.create(meetingDetails);

  /* Don't show ConnectToMeeting Button when connection is In-Person or Showcase with invalid configuration */

  /* ShowcasePagePath Connection Type with invalid configuration */
  if (connection && connection.isShowcaseConnectionType() && !eventSettings.showcaseEnabled) return (
    <div className='pt-4 whitespace-pre-wrap leading-loose'>{SHOWCASE_PAGE_PATH_CONNECTION_INVALID_TEXT}</div>
  );

  /* In-Person Connection Type  */
  if (connection && connection.isInPersonConnectionType()) return (
    <div className='pt-4 text-sm whitespace-pre-wrap break-words' dangerouslySetInnerHTML={{ __html: connection.meetingLocation }}/>
  );


  return (
    <>
      <JoinButton
        joinUrl={joinUrl}
        isDisabled={isConnectBtnDisabled}
        meetingDetails={meetingDetails}
        useConnectToMeetingMethod={useConnectToPrivateMeetingMethod}
        className='connect-to-meeting-btn rounded bg-primary text-white on-hover-shadow'
      />
    </>
  );
};

export default ConnectToMeeting;
