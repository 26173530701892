import React, { Reducer } from 'react';
import { CustomFieldsType } from '../../../../lib/api';
import { EMAIL_REGEXP, SHORT_PLAIN_TEXT_LENGTH, REQUIRED, FIRST_SPACE_TRIMMER, REQUIRED_CHECKBOX, STRING_WITHOUT_HTML_REGEX, REG_DATA } from '../../../../lib/constants';
import { getItemFromSessionStorage } from '../../../../lib/helpers/storage';
import { CustomFieldType } from './RegistrationForm';

export type Formatter = (value: string) => string;

export type ValidatorResultType = {
  valid: boolean;
  validationMessage: string | null;
};

export type ValidatorType = {
  validator: (value: string) => boolean;
  validationMessage: string;
};

type InputValidatorType = (isValidFn: ValidatorType, value: string) => ValidatorResultType;

export interface FieldsProps {
  name: string;
  label: string;
  required: boolean;
  validators?: ValidatorType[];
  optional?: boolean;
  formatters?: Formatter[];
  order: number;
  group?: FieldsProps[];
  fieldType?: string;
  optionsList?: string[];
  triaged?: boolean;
  visible?:boolean;
}

export const handleValidation: InputValidatorType = (isValidFn: ValidatorType, val: string): ValidatorResultType => {
  const valid = isValidFn.validator(val);
  const validationMessage = valid ? null : isValidFn.validationMessage;

  return { valid: valid, validationMessage: validationMessage };
};

const REQUIRED_MESSAGE = 'This field is required.';
const REQUIRED_MESSAGE_FOR_LABEL = ' is required.';

const EMAIL_ERROR_MESSAGE = 'Enter a valid email address.';
const MAX_LENGTH_ERROR_MESSAGE = 'This field allows 255 characters max.';

const emailValidators = [
  { validator: EMAIL_REGEXP, validationMessage: EMAIL_ERROR_MESSAGE },
  { validator: SHORT_PLAIN_TEXT_LENGTH, validationMessage: MAX_LENGTH_ERROR_MESSAGE }
];

const requiredEmailValidators = [
  { validator: REQUIRED, validationMessage: 'Email'+ REQUIRED_MESSAGE_FOR_LABEL },
  ...emailValidators
];

const textValidators = [{ validator: SHORT_PLAIN_TEXT_LENGTH, validationMessage: MAX_LENGTH_ERROR_MESSAGE }];
const requiredTextValidators = (label = '') => {
  const formattedLabel = label.replace(STRING_WITHOUT_HTML_REGEX, '');
  return [
    { validator: REQUIRED, validationMessage: label ? (formattedLabel + REQUIRED_MESSAGE_FOR_LABEL) : REQUIRED_MESSAGE },
    { validator: SHORT_PLAIN_TEXT_LENGTH, validationMessage: MAX_LENGTH_ERROR_MESSAGE }
  ];
};
const requiredCheckBoxValidator = [{ validator: REQUIRED_CHECKBOX, validationMessage: REQUIRED_MESSAGE }];

const trimFirstSpace = [FIRST_SPACE_TRIMMER];

const mandatoryEmailValidators = (mandatory: boolean) => mandatory ? requiredEmailValidators : emailValidators;
const mandatoryCustomFieldValidators = (mandatory: boolean, label?: string) => mandatory ? requiredTextValidators(label) : textValidators;
const mandatoryCheckBoxValidator = (mandatory: boolean): ValidatorType[] => mandatory ? requiredCheckBoxValidator : [];

const customFieldValidators = (formKey: string | undefined, fieldType: string | undefined, mandatory: boolean, label?: string) => {
  if (formKey === 'email' || formKey === 'assistantEmail') {
    return mandatoryEmailValidators(mandatory);
  } else if (fieldType === CustomFieldType.checkbox) {
    return mandatoryCheckBoxValidator(mandatory);
  } else {
    return mandatoryCustomFieldValidators(mandatory, label);
  }
};

export type ValuesProps = {
  [key: string]: string
};

export type TouchedProps = {
  [key: string]: boolean
};

export type ErrorProps = {
    [key: string]: ValidatorResultType[]
};
interface ReducerProps {
  isDisabledSubmit: boolean;
  values: ValuesProps;
  touched: TouchedProps;
  errors: ErrorProps;
}

type PayloadProp = {
  key: string;
  value: string;
  validators?: ValidatorType[];
};

export enum Types {
  onChangeAction = 'ON_CHANGE',
  onBlurAction = 'ON_BLUR',
}

export const AttendanceMethodOptionLabels = {
  'IN_PERSON': 'In Person',
  'VIRTUAL': 'Virtual',
  'BOTH': 'Both',
};

interface Payload {
  type: Types;
  payload: PayloadProp;
}

type UseValuesProps = {
  state: ReducerProps;
  handleChange: (
    key: string,
    value: string,
    validators: ValidatorType[]) => void;
  handleBlur: (
    key: string,
    value: string,
    validators: ValidatorType[]) => void;
}

const createFieldsSetting = (customFields:CustomFieldsType[]): FieldsProps[] => {
  if (!customFields?.length) return [];
  return customFields
    .sort((a, b) => a.positionOrder - b.positionOrder)
    .map(({
      default: isDefault,
      formKey,
      mandatory,
      positionOrder,
      customFieldId,
      label,
      fieldType,
      optionsList,
      triaged,
      visible,
    }) => {
      return ({
        name: isDefault ? formKey as string : `customField_${customFieldId}`,
        label: label,
        required: mandatory,
        optional: !mandatory,
        validators: customFieldValidators(formKey, fieldType, mandatory, label),
        formatters: trimFirstSpace,
        order: positionOrder,
        fieldType: fieldType ?? '',
        optionsList: optionsList ?? [],
        triaged: triaged,
        visible: visible,
      });
    });
};

const settings = (customFields: CustomFieldsType[] = []): FieldsProps[] =>
  createFieldsSetting(customFields)
;

const isFormValid = (errors: Record<string, ValidatorResultType[]>) => !Object.values(errors)
  .flatMap((currentValue: ValidatorResultType[] ): boolean[] => (currentValue || []).map(err => err.valid))
  .every(Boolean)
;

const reducer: Reducer<ReducerProps, Payload> = (state, action): ReducerProps => {
  const key = action.payload.key;
  const value = action.payload.value;
  const validators = action.payload.validators;
  const isValid = (validators ?? []).map(isValidFn => handleValidation(isValidFn, value ));
  const errors = { ...state.errors, [key]: isValid };
  const isDisabledSubmit = isFormValid(errors);
  switch (action.type) {
  case Types.onChangeAction:
    return {
      ...state,
      errors,
      isDisabledSubmit: isDisabledSubmit,
      values: { ...state.values, [key]: value }
    };
  case Types.onBlurAction:
    return {
      ...state,
      errors: errors,
      isDisabledSubmit: isDisabledSubmit,
      touched: { ...state.touched, [key]: true }
    };
  default:
    return state;
  }
};

const useValues = (fields: FieldsProps[], selectedTriageField?: Record<string, string>): UseValuesProps => {
  const storedRegistrationData = JSON.parse(getItemFromSessionStorage(REG_DATA) as string) || {};


  const initialValues = fields.filter(f => f.visible !== false).reduce((prev, next) => {
    const isStoredField = Reflect.has(storedRegistrationData, next.name);
    const isFieldTriageQuestion = !isStoredField && !!selectedTriageField && !!selectedTriageField[next.name];
    const validationHandler = isStoredField
      ? (next.validators ?? [])
        .map(isValidFn => handleValidation(isValidFn, storedRegistrationData[next.name]))
        .filter(({ valid }) => !valid)[0]
      : null
    ;
    const validation = [
      isFieldTriageQuestion || validationHandler === undefined
        ? { valid: true, validationMessage: null }
        : validationHandler
          ? { ...validationHandler }
          : { valid: !next.required, validationMessage: null }
    ];
    return ({
      ...prev,
      values: {
        ...prev.values,
        [next.name]: isStoredField
          ? storedRegistrationData[next.name]
          : isFieldTriageQuestion
            ? selectedTriageField?.[next.name]
            : next.fieldType === CustomFieldType.checkbox ? 'FALSE' : '',
      },
      touched: { ...prev.touched, [next.name]: isStoredField },
      errors: { ...prev.errors, [next.name]: validation },
      isDisabledSubmit: isFormValid({ ...prev.errors, [next.name]: validation })
    });
  },{
    isDisabledSubmit: true,
    values: {},
    touched: {},
    errors: {}
  });

  const [state, dispatch] = React.useReducer(reducer, initialValues);

  const handleChange = (key: string, value: string, validators: ValidatorType[]) => {
    dispatch({
      type: Types.onChangeAction,
      payload: { key, value, validators }
    });
  };

  const handleBlur = (key: string, value: string, validators: ValidatorType[]) => {
    dispatch({
      type: Types.onBlurAction,
      payload: { key, value, validators }
    });
  };

  return {
    state,
    handleChange,
    handleBlur,
  };
};


export { settings, useValues, mandatoryCheckBoxValidator };