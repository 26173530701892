import React, { FormEvent, useEffect, useRef, useState } from 'react';
import Input from '../../../../components/input';
import Form from '../../../../components/_base/Form';
import Button from '../../../../components/button';
import { registerAttendee } from '../../../../lib/api/public';
import ErrorBlock from '../../../../components/_base/ErrorBlock';
import { CustomFieldsType, PublicSessionMeetingDetails } from '../../../../lib/api';
import {
  ValuesProps,
  TouchedProps,
  ErrorProps,
  ValidatorType,
  settings,
  useValues,
  Formatter,
  FieldsProps,
} from './field-settings';
import { getFormattedValue } from '../../../../hooks/use-form-field';
import './RegistrationForm.scss';
import SelectBox from '../../../shared/SelectBox';
import SessionChoices from './SessionChoices';
import TextAsHTML from '../../../../components/_base/TextAsHTML';
import API, { PUBLIC } from '../../../../lib/api';
import { getItemFromSessionStorage, removeItemFromSessionStorage, setItemInSessionStorage } from '../../../../lib/helpers/storage';
import { REG_DATA } from '../../../../lib/constants';
import DefaultSpinner from '../../../shared/DefaultSpinner';

export type IRegistrationFormProps = {
  registrationSubscription: (response: { registrationId: string, appSyncToken: string }) => void;
  eventId: number;
  toggleRegistrationForm: () => void;
  showSubmitMessage: (v: boolean) => void;
  setErrorRes: (v: boolean) => void;
  customFields: CustomFieldsType[];
  setEmailText: (v: string) => void;
  registrationHeader: string | undefined;
  registrationInstructions: string | undefined;
  setEventToken: (eventToken: string) => void;
  location: { from?: string, errorMsg?: string; email?: string };
  isProRegistrationView: boolean | undefined;
  autoSignInEnabled: boolean | undefined;
  generalSessionList: PublicSessionMeetingDetails[];
  eventTimeZone: string;
  publicDescription: string;
  handleRedirect: (url: string) => void;
  selectedTriageAnswer: string;
  shouldRenderRegisterButton: boolean;
  showSpinner: boolean;
};

type InputProps = {
  i: number;
  label: string;
  name: string;
  values: ValuesProps;
  touched: TouchedProps;
  errors: ErrorProps;
  optional?: boolean;
  validators: ValidatorType[];
  formatters: Formatter[];
  handleChange: (
    key: string,
    value: string,
    validators: ValidatorType[]) => void;
  handleBlur: (
    key: string,
    value: string,
    validators: ValidatorType[]) => void;
  withOverflowEllipsis?: boolean,
  email?: string;
  onChangeCallback?: (key: string, value: string) => void;
};

export enum CustomFieldType {
  text = 'Text',
  first_name = 'firstName',
  last_name = 'lastName',
  dropdown = 'DROPDOWN',
  checkbox = 'CHECKBOX'
}

const InputBox: React.FC<InputProps> = (props: InputProps): JSX.Element => {
  const {
    i, name, label, optional, values,
    validators, touched, errors, withOverflowEllipsis,
    handleBlur, handleChange, formatters, email, onChangeCallback
  } = props;

  useEffect(() => {
    if (email) handleChange('email', email, validators);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    const value = getFormattedValue((e?.target?.value ?? ''), formatters);
    handleChange(name, value, validators);
    if (onChangeCallback) {
      onChangeCallback(name, value);
    }
  };

  const onBlur = (name: string) => {
    handleBlur(name, values[name], validators);
  };

  const checkErrors = touched[name] && (!errors[name].map(({ valid }) => valid).every(Boolean) || (!optional && !values[name]));

  const error = touched[name] && errors[name].find(err => !err.valid);
  return (
    <div className='mt-3 word-break' key={`${i}_${name}`}>
      <Input.Text
        id={name}
        name={name}
        label={label}
        onChange={onChange}
        error={checkErrors}
        value={values[name]}
        onBlur={() => onBlur(name)}
        span={optional ? '(optional)' : undefined}
        withOverflowEllipsis={withOverflowEllipsis}
      />
      <div className="registration-error-margin absolute">
        { error && <ErrorBlock errorMessage={error.validationMessage || ''} /> }
      </div>
    </div>
  );
};

const RegistrationForm: React.FC<IRegistrationFormProps> = (props: IRegistrationFormProps): JSX.Element => {
  const {
    eventTimeZone,
    generalSessionList,
    publicDescription,
    isProRegistrationView,
    selectedTriageAnswer,
    shouldRenderRegisterButton,
    showSpinner,
  } = props;
  const [ selectedMeetings, setSelectedMeetings] = useState<number[]>([]);
  const fields = settings(props.customFields);
  const triageQ = fields.find(f => f.triaged);
  const selectedAnswerFromOptionsList = triageQ?.optionsList?.find(o => o.toLowerCase() === selectedTriageAnswer.toLowerCase());
  const {
    state,
    handleChange,
    handleBlur
  } = useValues(fields, { [triageQ?.name as string]: selectedAnswerFromOptionsList as string });
  const userRegistrationData = useRef<Record<string, string>>({});

  const { values, touched, errors, isDisabledSubmit } = state;

  const subscribeToEventRegistration = async (eventId: string): Promise<string | null> => {
    try {
      const registrationParams = await API[PUBLIC].fetchRegistrationSubscriptionParams(eventId, encodeURIComponent(selectedTriageAnswer));
      props.showSubmitMessage(true);
      await props.registrationSubscription(registrationParams);

      return registrationParams.registrationId;
    } catch {
      return null;
    }
  };

  const registrationHandle = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    if (isDisabledSubmit) return;
    try {
      const eventId = props.eventId.toString();
      const params = {
        ...Object.keys(values).reduce<{[key: string]: string | null}>((result, key) => {
          result[key] = values[key].trim() || null;
          return result;
        }, {}),
        sessionMeetingIds: selectedMeetings
      };

      const registrationId = props.autoSignInEnabled ? await subscribeToEventRegistration(eventId) : null;

      if (registrationId) Reflect.set(params, 'registrationId', registrationId);

      if (triageQ && triageQ.visible === false && selectedTriageAnswer) {
        Reflect.set(params, triageQ.name, selectedAnswerFromOptionsList);
      }
      Reflect.set(params, 'formName', selectedTriageAnswer);

      const response = await registerAttendee(eventId, params);

      props.showSubmitMessage(true);
      props.setEmailText(values['email']);
      props.toggleRegistrationForm();
      response.eventToken && props.setEventToken(response.eventToken);
      removeItemFromSessionStorage(REG_DATA);
    } catch (e) {
      props.showSubmitMessage(false);
      props.setErrorRes(true);
    }
  };

  const className = 'editable-sign-in';
  const classNameRegistrationTitle = `${className}--title`;
  const classNameRegistrationSubmitButton = `${className}--submit-button`;

  const saveFormDataInStore = (key: string, value: string): void => {
    const currentUserRegistrationData = JSON.parse(getItemFromSessionStorage(REG_DATA) as string);
    userRegistrationData.current = {
      ...currentUserRegistrationData,
      [key]: value,
    };
    setItemInSessionStorage(REG_DATA, JSON.stringify(userRegistrationData.current));
  };
  const formattedFields = fields.reduce((accumulator , currentValue: FieldsProps) => {
    const [singleFields, nameGroup] = accumulator as [FieldsProps[], FieldsProps];
    const { order } = currentValue;
    const isNameField =
      currentValue.name === CustomFieldType.first_name ||
      currentValue.name === CustomFieldType.last_name;

    return isNameField ?
      [
        singleFields,
        nameGroup.group?.length ?
          {
            ...nameGroup,
            group:  nameGroup.group.concat(currentValue)
          } :
          {
            ...nameGroup,
            group: [currentValue],
            order: order,
          }
      ] :
      [ singleFields.concat(currentValue), nameGroup ];
  }, [[], {}]);

  useEffect(() => {
    const storedRegistrationData = JSON.parse(getItemFromSessionStorage(REG_DATA) as string);
    userRegistrationData.current = storedRegistrationData ? storedRegistrationData : {};
  }, []);

  useEffect(() => {
    const clearRegistrationData = () => {
      removeItemFromSessionStorage(REG_DATA);
    };

    window.addEventListener('beforeunload', clearRegistrationData);
    return () => {
      window.removeEventListener('beforeunload', clearRegistrationData);
    };
  }, []);


  return (
    <Form onSubmit={registrationHandle} name='RegistrationForm'>
      { !isProRegistrationView && <h1 className={`${classNameRegistrationTitle} h1-font-size leading-10 break-words`}>
        {props.registrationHeader || 'Register'}
      </h1> }
      { isProRegistrationView && (
        <div className='registration-form__public-description'>
          <TextAsHTML formattedText={publicDescription} />
        </div>
      )}
      {props.location?.errorMsg &&
        <div className='mt-3'>
          <span className='break-words font-bold'>
            {props.location.errorMsg}
          </span>
        </div>}
      <div className={`mt-3 ${isProRegistrationView ? 'registration-form__instructions--pro-view' : ''}`}>
        <span
          className='break-words'
          dangerouslySetInnerHTML={{ __html: props.registrationInstructions ?? '' }}
        >
        </span>
      </div>

      {(!showSpinner && selectedTriageAnswer)
        ? <DefaultSpinner visible={!showSpinner} />
        : <>
          {([...formattedFields[0] as FieldsProps[], formattedFields[1] as FieldsProps])
            .filter(f => Object.keys(f).length && f.visible !== false)
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((field, i) => {
              if (field.fieldType === CustomFieldType.dropdown || field.fieldType === CustomFieldType.checkbox) {
                return (
                  <SelectBox
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    key={field.name}
                    field={field}
                    values={values}
                    validators={field.validators ?? []}
                    errors={errors}
                    touched={touched}
                    optional={field.optional}
                    onChangeCallback={(key, value) => {
                      saveFormDataInStore(key, value);
                      if (field.triaged) {
                        props.handleRedirect(value);
                      }
                    }}
                  />
                );
              } else if (field.group) {
                return <div className='flex justify-between input-group' key={i}>
                  {
                    field.group.map((fieldInGroup: FieldsProps, j: number) => {
                      return (
                        <InputBox
                          i={j}
                          key={`group-${j}`}
                          name={fieldInGroup?.name}
                          label={fieldInGroup?.label}
                          touched={touched}
                          errors={errors}
                          values={values}
                          handleBlur={handleBlur}
                          optional={fieldInGroup?.optional}
                          handleChange={handleChange}
                          validators={fieldInGroup?.validators ?? []}
                          formatters={fieldInGroup?.formatters ?? []}
                          onChangeCallback={saveFormDataInStore}
                        />
                      );
                    })
                  }
                </div>;
              } else {
                return (
                  <InputBox
                    i={i}
                    key={i}
                    values={values}
                    errors={errors}
                    name={field.name}
                    touched={touched}
                    label={field.label}
                    handleBlur={handleBlur}
                    optional={field.optional}
                    handleChange={handleChange}
                    validators={field.validators ?? []}
                    formatters={field.formatters ?? []}
                    email={props.location?.email}
                    onChangeCallback={saveFormDataInStore}
                  />
                );
              }
            }
            )}
          { !!generalSessionList?.length &&
            (
              <SessionChoices
                data={generalSessionList}
                timeZone={eventTimeZone}
                onChange={(meetingIds: number[]) => setSelectedMeetings(meetingIds)}
                selectedMeetings={selectedMeetings}
              />
            )
          }
          { shouldRenderRegisterButton &&
            <div className={`${classNameRegistrationSubmitButton} flex justify-end mt-6`}>
              <Button.Submit
                name='registration'
                label='Register'
                size={'big'}
                disabled={isDisabledSubmit}
                className='min-w-full px-5 py-3 rounded bg-primary text-white on-hover-shadow'
              />
            </div>
          }
        </>}
    </Form>

  );
};

export default RegistrationForm;
