import React from 'react';
import { EmbeddedHTMLPageType } from '../../../lib/api/custom-page.types';


export const EmbeddedHTMLCustomPage: React.FunctionComponent<EmbeddedHTMLPageType> = (props: EmbeddedHTMLPageType): JSX.Element | null => {
  if (props && props.htmlUrl) return (
    <iframe
      width='100%'
      height='100%'
      title={props.label}
      src={props.htmlUrl}
      sandbox='allow-scripts allow-same-origin allow-forms allow-popups allow-modals'
    />
  );
  return null;
};
