import React from 'react';
import TextAsHTML from '../../../components/_base/TextAsHTML';
import { EventPrivateSettingsType } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import KnovioThumbnail from './KnovioThumbnail';
import WelcomeFooter from './WelcomeFooter';
import { EventPrivateDetailsResponseType } from '../../../lib/api';
import useEventCalendar from '../../../hooks/use-event-calendar';
import Buttons from '../../../components/button';
import './WelcomeBlock.scss';
import useEventToken from '../../../hooks/use-event-token';

const WelcomeBlock = (props: EventPrivateDetailsResponseType & EventPrivateSettingsType): JSX.Element => {
  const {
    name,
    eventId,
    welcomeLabel,
    privateDescription,
    welcomeKnovioEnabled,
    welcomeKnovioEmbedId,
    calendarButtonEnabled,
    eventTitleForPassport
  } = props;

  const token = useEventToken(eventId);
  const { fetchOutlookCalendarForEvent, fetchGoogleCalendarForEvent } = useEventCalendar();

  const getEventTitle = (customTitle: string | undefined, defaultTitle: string): string => {
    return customTitle ? customTitle : defaultTitle;
  };
  const fileName = `${getEventTitle(eventTitleForPassport, name)}.ics`;

  const blockClassName = 'overflow-hidden shadow-gray bg-white mx-auto md:w-3/4 mt-12 max-h-full ' +
    'h-full w-100 mx-0 min-h-24rem flex flex-col justify-between';
  const className = 'editable-welcome';
  const classNameWelcomeWrapper = `${className}--wrapper`;
  const classNameWelcomeDescription = `${className}--description`;
  const classNameWelcomeTitle = `${className}--title`;

  return (
    <div className={`${classNameWelcomeWrapper} ${blockClassName}`}>
      <div>
        <h1 className={`${classNameWelcomeTitle} font-size-40px`}>{welcomeLabel}</h1>
        <hr />
        {welcomeKnovioEnabled && welcomeKnovioEmbedId && <KnovioThumbnail welcomeKnovioEmbedId={welcomeKnovioEmbedId} />}
        <div className={`${classNameWelcomeDescription} truncate-advanced-15`}>
          <TextAsHTML formattedText={privateDescription} />
        </div>
        {calendarButtonEnabled &&
          <div className='add-to-calendar-btn--wrapper my-6 ml-5'>
            <Buttons.OptionsButton
              label={`Add to Calendar`}
              name={'addToCalendar'}
              disabled={false}
              eventId={eventId.toString() || ''}
              eventName={name || ''}
              className='connect-to-meeting-btn rounded bg-primary text-white on-hover-shadow flex'
              downloadIcsFile={async () => fetchOutlookCalendarForEvent(eventId.toString() || '', fileName, token)}
              openGoogleCalendar={async () => fetchGoogleCalendarForEvent(eventId.toString() || '', false, token)}
              withCalendarIcon
            />
          </div>
        }
      </div>
      <WelcomeFooter {...props} />
    </div>
  );
};

export default WelcomeBlock;
