import React from 'react';
import Button from '../../components/button';
import useConnectToPrivateMeetingMethod, { ConnectionPrivateDetailsType } from '../../hooks/use-connect-to-private-meeting-method';
import { ConnectionPublicDetailsType } from '../../hooks/use-connect-to-public-meeting-method';
import { PrivateMeetingDetailsResponseType, PublicMeetingDetailsResponseType } from '../../lib/api';
import { Connection } from '../../lib/connection/connection';
import { useCurrentUserInfo } from '../../hooks/api/protected/use-current-user-info';

export type WatchButtonProps = {
  isDisabled: boolean;
  meetingDetails: PrivateMeetingDetailsResponseType | PublicMeetingDetailsResponseType | void;
  className?: string;
  joinUrl?: string | null;
}

export const WatchButton: React.FC<WatchButtonProps> = (props: WatchButtonProps): JSX.Element | null => {
  type ConnectionDetailsType = ConnectionPrivateDetailsType & ConnectionPublicDetailsType;

  const { data } = useCurrentUserInfo();
  const { isDisabled, meetingDetails, className, joinUrl } = props;
  const connectDetails = isDisabled ? undefined : { ...props.meetingDetails, joinUrl };
  if (connectDetails) {
    const connection = (connectDetails as any).connection;
    //console.log('connection',connection,data);
    if (connection.joinMethods[0].type === 'OTHER' || connection.joinMethods[0].type === 'VERACAST') {
        connectDetails.joinUrl = connection.joinMethods[0].connectionLink+'/externalLogin?username='+data?.email;
    }
    else {
        connectDetails.joinUrl = connection.joinMethods[0].connectionLink;
    }
  }
  const connectToMeeting = useConnectToPrivateMeetingMethod(connectDetails as ConnectionDetailsType);
  if (!meetingDetails) return null;

  const connection = Connection.create(meetingDetails);
  const isKnovioConnectionPublish = connection?.isKnovioConnectionType() ? connection.isKnovioAvailable : true;
  const shouldDisableBtn = isDisabled || !isKnovioConnectionPublish || (!connection && !joinUrl);

  return (
    <div className='flex items-center'>
      <Button.Common
        name='watch'
        disabled={shouldDisableBtn}
        onClick={connectToMeeting}
        label='Watch'
        data-meeting-id={meetingDetails.meetingId}
        className={className}
      />
      {
        shouldDisableBtn && <div className='coming-soon'>Coming soon</div>
      }
    </div>
  );
};
