import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import EventDetails from '../../shared/EventDetails';
import { LayoutWrapper } from './LayoutWrapper';
import isEnabledRoute from '../../../hooks/use-enabled-route';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import { PassportPageType, SessionsPageType } from '../../../lib/api/custom-page.types';
import { ROOT } from '../../../config/routes/paths';

const SessionWrapper: React.FC = () => {
  const auth = useAuth() as IAuthContext;
  const isAuthenticated = !!auth.isAuthenticated;
  const isLoading = auth.isLoading;

  const eventData = useEventSettings() as EventSettingsContextType;

  if (!eventData || isLoading) return null;

  const pageConfiguration = eventData.customPages.find(el => el.type === PassportPageType.SESSIONS) as SessionsPageType;

  if (isAuthenticated && !eventData?.sessionEnabled) {
    const enabledRoute = isEnabledRoute(eventData?.landingPage);
    return <Redirect to={enabledRoute} />;
  }

  if (!isAuthenticated && !pageConfiguration?.publicEnabled) {
    return <Redirect to={ROOT} />;
  }

  if (eventData) return (
    <>
      <EventDetails details={eventData} />
      <LayoutWrapper
        event={{
          ...eventData,
          layout: pageConfiguration?.layout,
          sessionLabel: pageConfiguration.label,
          sessionNotes: pageConfiguration.sessionNotes,
        }}
      />
    </>
  );
  return null;
};

export default SessionWrapper;
