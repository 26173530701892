import React, { useEffect, useState } from 'react';
import { EventPublicDetailsResponseType } from '../../../lib/api';
import { Redirect } from "react-router-dom";
import LoginForm from "./LoginForm/LoginForm";
import EventPublicInfo from "./EventPublicInfo/EventPublicInfo";
import { AccessCodeReminder, getLabelForNotMandatoryField } from './AccessCodeReminder/AccessCodeReminder';
import RegisterBlock from './RegisterBlock/RegisterBlock';
import { IEventCustomSettings, useCustomSettings } from '../../../hooks/use-custom-settings';
import { EMAIL_REGEXP, FORM_VIEW, REQUEST_ACCESS_CODE, REQUIRED, SPACE_TRIMMER } from '../../../lib/constants';
import useFormField from '../../../hooks/use-form-field';
import { IAuthContext, useAuth } from '../../../lib/context-providers/auth-context';
import DefaultSpinner from '../../shared/DefaultSpinner';
import Icon from '../../../components/_base/Icon';
import ErrorBlock from '../../../components/_base/ErrorBlock';
import { useRegistrationSubscription } from "../../../hooks/use-registration-subscription";
import { REGISTRATION_PATH } from "../../../config/routes/paths";
import './LoginWrapper.scss';

type Props = {
  signIn(email: string, code: string): Promise<void>,
  data: EventPublicDetailsResponseType
}

const LoginWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const { signIn, data } = props;
  const auth = useAuth() as IAuthContext;
  const params = new URLSearchParams(window.location.search);
  /* FORM_VIEW for opening access code form, when user opens 'Preview button' from Access Code Popup in Central */
  const showAccessCodeForm = (data.signInLandingWith !== REQUEST_ACCESS_CODE && params.get(FORM_VIEW) !== 'access_code');
  const [showAccessCodeReminder, setShowAccessCodeReminder] = useState<boolean>(showAccessCodeForm);
  const [showSubmitMessage, setShowSubmitMessage] = useState<boolean>(false);
  const [showSubscriptionError, setSubscriptionError] = useState(false);
  const { registrationEnabled } = useCustomSettings() as IEventCustomSettings;
  const { state, subscribe } = useRegistrationSubscription();
  const subscriptionInProgress = !!(state.inProgress || (state.magicLink && !state.error));

  useEffect(()=> {
    if (!state.inProgress && !state.magicLink && state.error && !showSubscriptionError) {
      setSubscriptionError(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  useEffect(()=> {
    if (auth?.invalidLink && data.signInLandingWith !== REQUEST_ACCESS_CODE && showAccessCodeReminder) {
      setShowAccessCodeReminder(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[auth?.invalidLink]);

  const emailValidators = [REQUIRED, EMAIL_REGEXP];
  const formatters = [SPACE_TRIMMER];

  const emailField = useFormField('', emailValidators, formatters);

  const submitMessageDefault = `Thank you. If there is an Access Code
          associated with your email address, it will be sent to you by email. Please check your inbox.`;
  const accessCodeRequestedMessage = getLabelForNotMandatoryField(data.accessCodeRequestedMessage, submitMessageDefault);

  const classNameSignIn = 'editable-sign-in';
  const classNameSignInWrapper = `${classNameSignIn}--wrapper`;

  if (registrationEnabled && state.error && state.error?.reason === 'NOT_AN_ATTENDEE') {
    return (
      <Redirect
        to={{
          pathname: REGISTRATION_PATH,
          state: {
            errorMsg: 'Your email address is not currently on the list of registered attendees for this event. Please register below.',
            email: emailField.value
          }
        }}
      />);
  }

  return (
    <div className={`${classNameSignIn} flex justify-between flex-col md:flex-row`}>
      <EventPublicInfo data={data} />
      <div className='shadow-gray'>
        <div className={`${classNameSignInWrapper} bg-white-opacity-95 w-342px mt-6 md:mt-0 login-wrapper p-6 min-w-full md:min-w-0`}>
          { auth.passwordlessAuthInProgress ?
            <div className='flex justify-center'>
              <DefaultSpinner visible={auth.passwordlessAuthInProgress } text='Authenticating...'/>
            </div>
            :
            <>
              {/* If SSO-auth unsuccessful, show error message without login form */}
              {auth.ssoError ?
                <div className='error-form'>
                  <Icon.Warning width='100px' height='100px' viewBox="0 0 34 30" />
                  <ErrorBlock errorMessage={auth.ssoError?.message || ''} classname={'font-size-13px px-0'}/>
                  <div
                    onClick={() => auth.setSsoError(null)}
                    className='mt-8 flex justify-center font-size-13px font-medium text-primary cursor-pointer'
                  >
                    Sign in
                  </div>
                </div>
                :
                <>
                  {showSubscriptionError ?
                    <>
                      <span>{state.error?.message}</span>
                      <hr className='mt-4 mb-4'/>
                    </>
                    :
                    showSubmitMessage &&
                      <>
                        <div
                          className='word-break'
                          dangerouslySetInnerHTML={{ __html: accessCodeRequestedMessage || '' }}
                        />
                        {!subscriptionInProgress && <hr className={`mt-4 mb-4 ${!accessCodeRequestedMessage ? 'submit-message-section' : ''}`}/>}
                      </>
                  }
                  <DefaultSpinner visible={subscriptionInProgress} />
                  { !subscriptionInProgress && showAccessCodeReminder &&
                    <LoginForm
                      signIn={signIn}
                      errorMsg={data.loginErrorMessage}
                      hideSubmitMessage={() => setShowSubmitMessage(false)}
                      showSubmitMessage={showSubmitMessage}
                      showAccessCodeReminder={() => {
                        setShowAccessCodeReminder(!showAccessCodeReminder);
                        setSubscriptionError(false);
                      }}
                      emailField={emailField}
                      privacyPolicyEnabled={data.privacyPolicyEnabled}
                      privacyPolicyLabel={data.privacyPolicyLabel}
                    />}
                  {!subscriptionInProgress &&
                    <AccessCodeReminder
                      eventId={data.eventId}
                      shouldShowAccessCodeReminder={showAccessCodeReminder}
                      setShowSubmitMessage={setShowSubmitMessage}
                      showSubmitMessage={showSubmitMessage}
                      showAccessCodeReminder={() => setShowAccessCodeReminder(!showAccessCodeReminder)}
                      emailField={emailField}
                      registrationSubscription={subscribe}
                      accessCodeHeader={data.accessCodeHeader}
                      accessCodeInstructions={data.accessCodeInstructions}
                      accessCodeNavigationButtonText={data.accessCodeNavigationButtonText}
                      accessCodeRequestButtonText={data.accessCodeRequestButtonText}
                      resetSubscriptionError={()=> setSubscriptionError(false)}
                      privacyPolicyEnabled={data.privacyPolicyEnabled}
                      privacyPolicyLabel={data.privacyPolicyLabel}
                      requestCodeSignInEnabled={data.requestCodeSignInEnabled}
                    />}
                </>
              }
            </>
          }
        </div>
        {!subscriptionInProgress && registrationEnabled && !auth.passwordlessAuthInProgress &&
          <RegisterBlock />}
      </div>
    </div>
  );
};

export default LoginWrapper;
