import React, { useEffect, useState, useRef, RefObject } from 'react';
import Button from '.';
import './OptionsButton.scss';
import Icon from '../../components/_base/Icon';
import { PRIMARY_COLOR } from '../../lib/constants';
import { useCustomSettings } from '../../hooks/use-custom-settings';
import { MediaQueryContextType, useMediaQuery } from '../../lib/context-providers/media-query-provider';
export interface ButtonProp {
  label: string;
  name: string;
  disabled?: boolean;
  className?: string | null;
  eventId: string;
  eventName: string;
  iconButton?: boolean;
  downloadIcsFile: () => void;
  openGoogleCalendar: () => void;
  withCalendarIcon?: boolean;
  customStyles?: React.CSSProperties;
  onShowDropDown?: (ref: RefObject<HTMLDivElement>) => void;
}

export const OptionsButton: React.FunctionComponent<ButtonProp> = (props: ButtonProp): JSX.Element => {
  const {
    label,
    name,
    disabled,
    className = '',
    iconButton = false,
    downloadIcsFile,
    openGoogleCalendar,
    withCalendarIcon = false,
    customStyles = {},
    onShowDropDown
  } = props;
  const ariaLabelForToggleButton = 'Add to Calendar options';

  const { primaryColor } = useCustomSettings();
  const mediaQuery = useMediaQuery() as MediaQueryContextType;
  const isMobile = mediaQuery?.isMobile;

  const optionsButtonRef: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);
  const [showDropDown, setShowDropDown] = useState(false);

  const handleOutsideClick = (e: Event) => {
    const target = e.target as HTMLElement;
    if (optionsButtonRef?.current?.contains(target)) {
      return;
    }
    setShowDropDown(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleDropDown = () => {
    if (!showDropDown && onShowDropDown) onShowDropDown(optionsButtonRef);
    setShowDropDown(prev => !prev);
  };

  const closeItem = () => {
    setShowDropDown(false);
  };

  const getListItems = () => {
    return (
      <div className={iconButton ? 'select-box' : ''}>
        <div
          style={{ ...customStyles, display: showDropDown ? 'block' : 'none' }}
          className={`select-box--items ${iconButton ? 'select-box--right' : ''}`}
          onClick={() => closeItem()}
        >
          <Button.Common
            name='addtoGoogleCalendar'
            ariaLabel='Add to Google Calendar'
            className='select-box--items--option bg-cool-gray-hover '
            onClick={openGoogleCalendar}
          >
            <Icon.GoogleCalendar width='30px' height='30px' viewBox="0 0 50 50" />
            <span className='select-box--option'>Add to Google Calendar</span>
          </Button.Common>
          <Button.Common
            className='bg-cool-gray-hover'
            name={`${props.eventName}.ics`}
            ariaLabel='Add to Outlook Calendar'
            onClick={() => {
              downloadIcsFile();
            }}
          >
            <Icon.OutlookCalendar width='30px' height='30px' viewBox="0 0 50 50" />
            <span className='select-box--option'>Add to Outlook Calendar (.ics)</span>
          </Button.Common>
        </div>
      </div>
    );
  };

  return (
    <div ref={optionsButtonRef} className={`${!isMobile ? 'select-box--container' : ''}`}>
      {iconButton ?
        <Button.Common
          name={name}
          onClick={() => toggleDropDown()}
          className='calendar-button transparent-bg without-after'
          ariaLabel={ariaLabelForToggleButton}
          aria-haspopup='true'
          aria-expanded={showDropDown ? 'true' : 'false'}
        >
          <div className='on-hover-shadow px-2 pb-2 rounded' style={{ display: 'inline-block' }}>
            <Icon.AddToCalendar width='24px' height='25px' viewBox="0 0 50 50" fill={primaryColor ?? PRIMARY_COLOR} className='text-primary' />
          </div>
        </Button.Common>
        :
        <Button.Common
          ariaLabel={ariaLabelForToggleButton}
          name={name}
          onClick={() => toggleDropDown()}
          label={label}
          disabled={disabled}
          className={`add-to-calendar-btn rounded text-center bg-white outline outline-mid-gray on-hover-shadow font-medium text-primary font-size-13px ${className} ${showDropDown ? `select-box--button` : ``}`}
        >
          {withCalendarIcon && <Icon.AddToCalendar width='20px' height='20px' viewBox="0 0 50 50" className='text-primary fill-white col order-first mr-4' />}
        </Button.Common>
      }
      {getListItems()}
    </div>
  );
};

export default OptionsButton;
