import React, { FormEvent } from 'react';
import Form from '../../../../components/_base/Form';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import { requestAccessCode, fetchRequestAccessCodeParameters } from '../../../../lib/api/public';
import { IAuthContext, useAuth } from '../../../../lib/context-providers/auth-context';
import { CheckBoxMapper, CheckBoxValues, ML_ACCESS_TOKEN } from '../../../../lib/constants';
import { useHistory } from 'react-router-dom';
import Checkbox from '../../../../components/_base/Checkbox';
import { mandatoryCheckBoxValidator, settings, useValues } from '../../RegistrationWrapper/RegistrationForm/field-settings';
import { CancelSubscription } from '../../../../hooks/use-registration-subscription';
import { HTTP_STATUS } from '../../../../config/api';

export interface IProps {
  shouldShowAccessCodeReminder: boolean;
  showAccessCodeReminder: () => void;
  setShowSubmitMessage: (v: boolean) => void;
  eventId: number;
  emailField: {
    value: string,
    onChange(e?: React.ChangeEvent<HTMLInputElement> | string): void,
    isValid: boolean
  };
  showSubmitMessage: boolean;
  registrationSubscription: (response: { registrationId: string, appSyncToken: string }) => Promise<void | CancelSubscription>;
  accessCodeHeader: string;
  accessCodeInstructions: string;
  accessCodeNavigationButtonText: string;
  accessCodeRequestButtonText: string;
  resetSubscriptionError: () => void;
  privacyPolicyEnabled: boolean;
  privacyPolicyLabel: string;
  requestCodeSignInEnabled: boolean;
}

const defaultValues = {
  accessCodeHeader: 'Sign In',
  accessCodeInstructions: 'Please enter your email address below to request your Access Code',
  accessCodeNavigationButtonText: 'Sign in with Access Code',
  accessCodeRequestButtonText: 'Request Access Code'
};


export const getLabelForNotMandatoryField = (value: string | null | undefined, defaultValue: string): string | null => {
  let label = null;
  if (value) {
    label = value;
  } else if (value === '') {
    label = '';
  } else {
    label = defaultValue;
  }
  return label;
};

export const AccessCodeReminder = (props: IProps): JSX.Element => {

  const {
    eventId,
    emailField,
    accessCodeHeader,
    showSubmitMessage,
    privacyPolicyLabel,
    setShowSubmitMessage,
    privacyPolicyEnabled,
    accessCodeInstructions,
    showAccessCodeReminder,
    resetSubscriptionError,
    registrationSubscription
  } = props;
  const history = useHistory();

  const privacyPolicyCheckBoxField = settings([{ label: privacyPolicyLabel, mandatory: true, positionOrder: -1 }]);

  const {
    state,
    handleChange,
  } = useValues(privacyPolicyCheckBoxField);

  const isValid = (privacyPolicyEnabled ? CheckBoxMapper[state.values[privacyPolicyLabel]] : true) && [emailField.isValid].every(Boolean);

  const isFieldTouched = state.touched[privacyPolicyLabel];
  const isError: boolean = isFieldTouched && (!state.errors[privacyPolicyLabel].map(({ valid }) => valid).every(Boolean) || (!privacyPolicyCheckBoxField && !state.values[privacyPolicyLabel]));

  const resetUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get(ML_ACCESS_TOKEN)) {
      params.delete(ML_ACCESS_TOKEN);

      history.replace({
        search: params.toString(),
      });
    }
  };

  const requestAccessCodeAndSignIn = async (): Promise<void> => {
    let unsubscribe: CancelSubscription | void;
    try {
      const subscriptionParams = await fetchRequestAccessCodeParameters(eventId.toString());
      unsubscribe = await registrationSubscription(subscriptionParams);
      await requestAccessCode(eventId.toString(), emailField.value, subscriptionParams.registrationId);
    } catch (e) {
      /* Stop subscription if user email is denied or reached timeout */
      if (e?.status === HTTP_STATUS.FORBIDDEN) {
        if (unsubscribe) unsubscribe();
      } else {
        /* Request access code if subscription process fails */
        await requestAccessCode(eventId.toString(), emailField.value);
      }
    }
  };

  const requestAccessCodeHandler = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setShowSubmitMessage(true);

    try {
      if (props.requestCodeSignInEnabled) {
        await requestAccessCodeAndSignIn();
      } else {
        await requestAccessCode(eventId.toString(), emailField.value);
      }
    } catch {
      /* no-option */
    }

    showAccessCodeReminder();
    resetUrlParams();
  };

  const className = 'editable-sign-in';
  const classNameSignInAccessCode = props.shouldShowAccessCodeReminder ? `${className}--access-code` : `${className}--back-to-sign-in`;
  const classNameSignInSubmitButton = `${className}--access-submit-button`;
  const auth = useAuth() as IAuthContext;

  const header = getLabelForNotMandatoryField(accessCodeHeader, defaultValues.accessCodeHeader);
  const instructions = getLabelForNotMandatoryField(accessCodeInstructions, defaultValues.accessCodeInstructions);

  const accessCodeNavigationButtonText = props.accessCodeNavigationButtonText || defaultValues.accessCodeNavigationButtonText;
  const accessCodeRequestButtonText = props.accessCodeRequestButtonText || defaultValues.accessCodeRequestButtonText;

  const changeFormValues = (): void => {
    emailField.onChange('');
    state.values[privacyPolicyLabel] = CheckBoxValues.FALSE;
  };

  return (
    <>
      {props.shouldShowAccessCodeReminder && !showSubmitMessage &&
        <button
          name='Request Access Code'
          onClick={() => {
            setShowSubmitMessage(false);
            auth.resetInvalidLink();
            showAccessCodeReminder();
            changeFormValues();
          }}
          className='on-hover-shadow flex text-primary bg-white rounded-5px justify-center mt-8 py-1 px-4 cursor-pointer font-size-13px m-x-auto font-medium word-break'
        >
          {accessCodeRequestButtonText}
        </button>
      }
      {!props.shouldShowAccessCodeReminder &&
        <>
          {header && <h1 className={`${classNameSignInAccessCode} h1-font-size leading-10 word-break`}>{header}</h1>}

          <Form onSubmit={requestAccessCodeHandler} name='accessCodeForm'>
            {auth.invalidLink
              ? <small>That link is invalid, please enter your email below to request a new link.</small>
              : <div className='word-break font-size-14px mt-29px' dangerouslySetInnerHTML={{ __html: instructions || '' }} />
            }
            <div className='mt-4'>
              <Input.Email
                id='email'
                name='email'
                autoFocus
                placeholder='name@email.com'
                label='Email Address'
                {...emailField}
              />
            </div>
            {privacyPolicyEnabled &&
              <div className='mt-4 word-break'>
                <Checkbox
                  label={privacyPolicyLabel}
                  onChange={(e: React.FormEvent<HTMLInputElement>): void => {
                    handleChange(
                      privacyPolicyLabel,
                      `${e.currentTarget.checked}`.toUpperCase(),
                      mandatoryCheckBoxValidator(privacyPolicyEnabled)
                    );
                  }}
                  value={CheckBoxMapper[state.values[privacyPolicyLabel]]}
                  error={isError}
                />
              </div>
            }
            <div className={`${classNameSignInSubmitButton} flex justify-end mt-8`}>
              <Button.Submit
                name='requestAccessCode'
                label={props.shouldShowAccessCodeReminder ? 'Submit' : 'Continue'}
                size='big'
                disabled={!isValid}
                className='min-w-full outline-auto-visible px-5 py-3 rounded bg-primary text-white on-hover-shadow'
              />
            </div>
            <button
              name='Sign in with Access Code'
              onClick={() => {
                showAccessCodeReminder();
                emailField.onChange('');
                resetUrlParams();
                resetSubscriptionError();
              }}
              className={`flex mt-8 text-primary bg-white justify-center cursor-pointer font-size-13px m-x-auto font-medium word-break rounded px-4 py-1 on-hover-shadow`}
            >
              {accessCodeNavigationButtonText}
            </button>
          </Form>
        </>
      }
    </>
  );
};
