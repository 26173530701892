import React from 'react';
import { KnovioPageType } from '../../../lib/api/custom-page.types';
import { EmbedContainer } from '../../../lib/kv-embed';


export const KnovioCustomPage: React.FunctionComponent<KnovioPageType> = (props: KnovioPageType): JSX.Element | null => {

  if (props.embedCodeId?.includes('veracast')) {
    /*
    return <div>this is veracast player: {props.embedCodeId}</div>;
    */
    return <EmbedContainer.Veracast embedId={props.embedCodeId} />;
  }
  else if (props) return <EmbedContainer.Knovio embedId={props.embedCodeId} />;

  return null;
};
