import { useHistory, generatePath } from 'react-router-dom';
import { SHOWCASE_PATH, CONNECTION_KNOVIO_PATH } from '../config/routes/paths';
import { PrivateMeetingDetailsResponseType } from '../lib/api';
import { Connection } from '../lib/connection/connection';

export type ConnectionPrivateDetailsType = (PrivateMeetingDetailsResponseType & { joinUrl?: string }) | void;

/*
* Connection method precedence:
* 1 - Attendee level connection
* 1.1 - embedCodeId or showcasePagePath
* 1.2 - connectionLink
* 2 - Link set for an Attendee
*/

export const openWindow = (url?: string): void => {
  window.open(url ?? '', '_blank');
};

export default function useConnectToPrivateMeetingMethod(details: ConnectionPrivateDetailsType): () => void {
  const history = useHistory();

  const connection = Connection.create(details as PrivateMeetingDetailsResponseType);

  return () => {
    if (!details) return;

    const { meetingId, attendeeLink, joinUrl } = details;

    if (joinUrl) {
      return openWindow(joinUrl);
    }

    if (connection) {
      if (connection.isKnovioConnectionType()) return openWindow(generatePath(CONNECTION_KNOVIO_PATH, { meetingId }));

      if (connection.isShowcaseConnectionType()) return history.push(SHOWCASE_PATH, { showcasePagePath: connection.showcasePagePath });

      return openWindow(connection.connectionLink);
    }

    return openWindow(attendeeLink);
  };
}
