import React, { useEffect } from 'react';
import useAttendeeToken from '../../../hooks/use-attendee-token';
import useConnectToPublicMeetingMethod from '../../../hooks/use-connect-to-public-meeting-method';
import PasscodeModal from './passcode-modal';
import useMeetingPublicDetails, { useMeetingDetailsWithPasscode } from '../../../hooks/api/public/use-meeting-public-details';
import JoinButton from '../../shared/JoinButton';
import './meeting-details-wrapper.scss';
import MeetingLinkEventDetails from '../../shared/MeetingLinkEventDetails';
import { BACKGROUND_IMG_URL, PRIMARY_COLOR } from '../../../lib/constants';
import MeetingLinkMeetingDetails from './MeetingLinkMeetingDetails';
import { Connection } from '../../../lib/connection/connection';

type Props = Record<string, unknown>

export const MeetingDetailsWrapper: React.FC<Props> = (props: Props): JSX.Element | null => {
  const noAttendeeToken = !useAttendeeToken();
  const { data, error = { message: '' }, isLoading } = useMeetingPublicDetails();
  /* Disable authorization with passcode if attendeeToken provided, as this story isn't implemented yet QU-6688 */
  const { setPasscode: authorize, error: inputError } = useMeetingDetailsWithPasscode(noAttendeeToken);
  const errorMessage = inputError ? inputError.message : error.message;
  const customStyle = { '--primary-color': data?.primaryColor || PRIMARY_COLOR } as React.CSSProperties;

  useEffect(() => {
    const signInPage = document.getElementById('sign-in-page') as HTMLDivElement;
    if (signInPage && data) {
      signInPage.style.cssText = `
        background-image: url(${data?.backgroundFile || BACKGROUND_IMG_URL});
      `;
    }

    return () => {
      if (signInPage) {
        signInPage.removeAttribute('style');
      }
    };
  }, [data]);

  if (!isLoading && !data) return <PasscodeModal errorMessage={errorMessage} onSetPasscode={authorize} />;
  if (data) {
    const eventInfo = {
      startTime: data.eventStartDateTime,
      endTime: data.eventEndDateTime,
      name: data.eventName,
      timeZone: data.timeZone,
      logoTitle: data.logoTitle,
      logoUrl: data.logoUrl,
      publicDescription: data.publicDescription,
      eventTitleForPassport: data.eventTitleForPassport,
      timeZoneDisplayType: data.timeZoneDisplayType,
      customTimeZoneDisplay: data?.customTimeZoneDisplay || '',
    };

    const connection = Connection.create(data);



    const isConnectToMeetingAllowed = (connection: Connection | null): boolean => {
      if (!connection) return false;
      if (connection.isShowcaseConnectionType() || connection.isKnovioConnectionType()) return true;
      if (connection.isRegularConnectionType() || connection.isWebinarConnectionType()) {
        return !!connection.connectionLink;
      }
      return false;
    };

    return (
      <section style={customStyle} className='meeting-details-wrapper lg:pl-6 lg:pr-6'>
        <div className='meeting-link'>
          <div className='meeting-link--wrapper'>
            <MeetingLinkEventDetails details={eventInfo}/>
            <MeetingLinkMeetingDetails
              eventType={data.type}
              meetingDetails={data}
              timeZone={data.timeZone}
              showAddToCalendarBtn={true}
              showDetailsAllowed
            >
              {
                isConnectToMeetingAllowed(connection) &&
                  <JoinButton
                    isDisabled={!data}
                    meetingDetails={data}
                    useConnectToMeetingMethod={useConnectToPublicMeetingMethod}
                  />
              }
            </MeetingLinkMeetingDetails>

          </div>
        </div>
      </section>
    );
  }
  return null;
};

export default MeetingDetailsWrapper;
