import React from 'react';
import { EmbeddedWebPageType } from '../../../lib/api/custom-page.types';


export const EmbeddedUrlCustomPage: React.FunctionComponent<EmbeddedWebPageType> = (props: EmbeddedWebPageType): JSX.Element | null => {
  if (props && props.embeddedWebpageUrl) return (
    <iframe
      width='100%'
      height='100%'
      title={props.label}
      src={props.embeddedWebpageUrl}
      sandbox='allow-scripts allow-same-origin  allow-forms allow-popups'
    />
  );
  return null;
};
