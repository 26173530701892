import { getPageConfigByType, isDefaultPage } from '../../../hooks/use-custom-routes';
import {
  AgendaPageType,
  CustomPageProps,
  defaultPagePathByType,
  PassportPageType,
  ReplaysPageType,
  SessionLayout,
  SessionsPageType,
  ShowcasePageType,
  SpeakersPageType,
  SupportPageType,
  UpcomingSessionsTypeOptions,
  WelcomePageType,
} from '../../api/custom-page.types';

type ConfigComposer<T> = (pages: CustomPageProps[]) => T;

export type LandingPageProps = { landingPage: string };
export const composeLandingPageProps: ConfigComposer<LandingPageProps> = (pages) => {
  let landingPage = '';
  const defaultPage = pages.find(page => page.landingPage) as CustomPageProps;

  if (defaultPage) {
    landingPage = isDefaultPage(defaultPage) ? defaultPagePathByType[defaultPage.type]:`/${defaultPage.path}`;
  }

  return { landingPage };
};

export type ShowcaseProps = { showcaseEnabled: boolean, showcaseLabel: string, showcaseEmbedId: string, showcaseLinkDescription: string };
export const composeShowcaseProps: ConfigComposer<ShowcaseProps> = (pages) => {
  const page = getPageConfigByType(pages, PassportPageType.SHOWCASE) as ShowcasePageType;

  return {
    showcaseEnabled: !!page && page.enabled,
    showcaseLabel: page && page.label,
    showcaseEmbedId: page && page.embedCodeId,
    showcaseLinkDescription: page && page.linkDescription
  };
};

export type WelcomeProps = {
  welcomeEnabled: boolean,
  welcomeLabel: string,
  welcomeKnovioEnabled: boolean,
  welcomeKnovioEmbedId: string,
  calendarButtonEnabled: boolean,
};
export const composeWelcomeProps: ConfigComposer<WelcomeProps> = (pages) => {
  const page = getPageConfigByType(pages, PassportPageType.WELCOME) as WelcomePageType;

  return {
    welcomeEnabled: !!page && page.enabled,
    welcomeLabel: page && page.label,
    welcomeKnovioEnabled: !!page && page.welcomeKnovioEnabled,
    welcomeKnovioEmbedId: page && page.embedCodeId,
    calendarButtonEnabled: !!page && page.calendarButtonEnabled,
    eventToken: page && page.eventToken
  };
};

export type SupportProps = { supportInformation: string, supportLabel: string, supportEnabled: boolean };
export const composeSupportProps: ConfigComposer<SupportProps> = (pages) => {
  const page = getPageConfigByType(pages, PassportPageType.SUPPORT) as SupportPageType;

  return {
    supportInformation: page && page.supportInformation,
    supportLabel: page && page.label,
    supportEnabled: !!page && page.enabled
  };
};

export type AgendaProps = {
  agendaEnabled: boolean,
  agendaLabel: string,
  agendaLinkDescription?: string,
  topicEnabled: boolean,
  scheduleNotes: string,
};
export const composeAgendaProps: ConfigComposer<AgendaProps> = (pages) => {
  const page = getPageConfigByType(pages, PassportPageType.AGENDA) as AgendaPageType;

  return {
    agendaEnabled: !!page && page.enabled,
    agendaLabel: page && page.label,
    agendaLinkDescription: page && page.linkDescription,
    topicEnabled: !!page && page.topicEnabled,
    scheduleNotes: !!page && page.scheduleNotes,
  };
};

export type SessionsProps = {
  sessionEnabled: boolean,
  sessionLinkDescription?: string,
  sessionLabel: string,
  layout: SessionLayout,
  preRegistrationEnabled: boolean
  sessionNotes: string,
};
export const composeSessionsProps: ConfigComposer<SessionsProps> = (pages) => {
  const page = getPageConfigByType(pages, PassportPageType.SESSIONS) as SessionsPageType;

  return {
    sessionEnabled: !!page && page.enabled,
    sessionLabel: page && page.label,
    layout: page && page.layout,
    sessionLinkDescription: page && page.linkDescription,
    preRegistrationEnabled: !!page && page.preRegistrationEnabled,
    sessionNotes: !!page && page.sessionNotes,
  };
};

export type SpeakersProps = { speakersEnabled: boolean, speakersLabel: string };
export const composeSpeakersProps: ConfigComposer<SpeakersProps> = (pages) => {
  const page = getPageConfigByType(pages, PassportPageType.SPEAKERS) as SpeakersPageType;

  return {
    speakersEnabled: !!page && page.enabled,
    speakersLabel: page && page.label
  };
};

export type ReplaysProps = {
  replaysEnabled: boolean,
  replaysLabel: string,
  upcomingSessionType: UpcomingSessionsTypeOptions,
  upcomingSessionsEnabled: boolean,
  upcomingSessionsLabel: string,
  replaysSessionLabel: string,
  replaysPreRegistrationEnabled: boolean,
  replaysNotes: string
  replaysLayout: string
};
export const composeReplaysProps: ConfigComposer<ReplaysProps> = (pages) => {
  const page = getPageConfigByType(pages, PassportPageType.REPLAYS) as ReplaysPageType;

  return {
    replaysEnabled: !!page && page.enabled,
    replaysLabel: page && page.label,
    upcomingSessionType: page && page.upcomingSessionType,
    upcomingSessionsEnabled: page && page.upcomingSessionsEnabled,
    upcomingSessionsLabel: page && page.upcomingSessionsLabel,
    replaysSessionLabel: page && page.replaysSessionLabel,
    replaysPreRegistrationEnabled: page && page.replaysPreRegistrationEnabled,
    replaysNotes: page && page.replaysNotes,
    replaysLayout: page && page.replaysLayout,
  };
};

export const composersList = [
  composeLandingPageProps,
  composeShowcaseProps,
  composeWelcomeProps,
  composeSupportProps,
  composeAgendaProps,
  composeSessionsProps,
  composeSpeakersProps,
  composeReplaysProps
];
