import React, { FormEvent, useState } from 'react';
import Input from '../../../../components/input';
import Form from '../../../../components/_base/Form';
import Button from '../../../../components/button';
import useFormField from '../../../../hooks/use-form-field';
import ErrorBlock from '../../../../components/_base/ErrorBlock';
import { IAuthContext, useAuth } from '../../../../lib/context-providers/auth-context';
import { useCustomSettings } from '../../../../hooks/use-custom-settings';
import { CheckBoxMapper, MIN6CHAR, REQUIRED, SPACE_TRIMMER } from '../../../../lib/constants';
import Checkbox from '../../../../components/_base/Checkbox';
import { mandatoryCheckBoxValidator, settings, useValues } from '../../RegistrationWrapper/RegistrationForm/field-settings';
import { getLabelForNotMandatoryField } from "../AccessCodeReminder/AccessCodeReminder";

type Props = {
  signIn(email: string, code: string): Promise<void>,
  errorMsg?: string,
  hideSubmitMessage: () => void,
  showSubmitMessage: boolean,
  emailField?: {
    value: string,
    onChange(e?: React.ChangeEvent<HTMLInputElement> | string): void,
    isValid: boolean
  };
  emailText?: string;
  showAccessCodeReminder?: () => void;
  privacyPolicyEnabled: boolean;
  privacyPolicyLabel: string;
  isProRegistrationView?: boolean;
}

const accessCodeValidators = [REQUIRED, MIN6CHAR];
const formatters = [SPACE_TRIMMER];

const LoginForm: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    signIn,
    emailText,
    emailField,
    hideSubmitMessage,
    showSubmitMessage,
    privacyPolicyLabel,
    privacyPolicyEnabled,
    showAccessCodeReminder,
    isProRegistrationView
  } = props;


  const { loginErrorMessage, loginInstructions, signInPageLabel } = useCustomSettings();
  const [isDisabled, setDisabled] = useState<boolean>(false);

  const accessCodeFiled = useFormField('', accessCodeValidators, formatters);
  const { authError, resetAuthError, resetInvalidLink } = useAuth() as IAuthContext;
  const dismissErrors = () => {
    setDisabled(false);
    resetAuthError();
  };

  const privacyPolicyCheckBoxField = settings([{ label: privacyPolicyLabel, mandatory: true, positionOrder: -1 }]);

  const {
    state,
    handleChange,
  } = useValues(privacyPolicyCheckBoxField);

  const isValid = (privacyPolicyEnabled ? CheckBoxMapper[state.values[privacyPolicyLabel]] : true) && (emailField?.isValid ? [emailField.isValid, accessCodeFiled.isValid].every(Boolean) : accessCodeFiled.isValid);

  const isFieldTouched = state.touched[privacyPolicyLabel];
  const isError: boolean = isFieldTouched && (!state.errors[privacyPolicyLabel].map(({ valid }) => valid).every(Boolean) || (!privacyPolicyCheckBoxField && !state.values[privacyPolicyLabel]));

  const signInHandle = (e: FormEvent) => {
    e.preventDefault();
    setDisabled(true);
    return signIn(emailField?.value || emailText || '', accessCodeFiled.value);
  };

  const className = 'editable-sign-in';
  const classNameSignInTitle = `${className}--title`;
  const classNameSignInSubmitButton = `${className}--submit-button`;

  const signInWithDifferentEmail = () => {
    if (emailField) {
      emailField.onChange('');
    }
    if (showAccessCodeReminder) {
      showAccessCodeReminder();
    }
    hideSubmitMessage();
    resetInvalidLink();
  };

  const defaultValues = {
    signInPageLabel: 'Sign In',
  };
  const signInLabel = getLabelForNotMandatoryField(signInPageLabel, defaultValues.signInPageLabel);

  return (
    <Form className='mt-4' onSubmit={signInHandle} name='loginForm'>
      { !isProRegistrationView && <h1 className={`${classNameSignInTitle} h1-font-size leading-10`}>
        {signInLabel}
      </h1> }
      {!showSubmitMessage && loginInstructions &&
        <div className='mt-29px break-words' dangerouslySetInnerHTML={{ __html: loginInstructions || '' }} />
      }
      <div className='mt-29px'>
        {emailField && !showSubmitMessage && (
          <Input.Email
            id='email'
            name='email'
            autoFocus
            placeholder='name@email.com'
            label='Email Address'
            error={!!authError}
            onFocus={dismissErrors}
            disabled={false}
            {...emailField}
          />
        )}
        {
          showSubmitMessage && (
            <>
              <label className='block font-bold mt-3 text-sm mb-2' htmlFor='accessCode'>Email Address</label>
              <div className='text-sm'>{emailText || emailField?.value}</div>
            </>
          )
        }
      </div>
      <div className='mt-29px'>
        <Input.Password
          id='accessCode'
          name='accessCode'
          autoFocus={!!emailField?.value}
          label='Access Code'
          error={!!authError}
          onFocus={dismissErrors}
          {...accessCodeFiled}
        />
      </div>
      {privacyPolicyEnabled &&
        <div className='mt-29px word-break'>
          <Checkbox
            label={privacyPolicyLabel}
            onChange={(e: React.FormEvent<HTMLInputElement>): void => {
              handleChange(
                privacyPolicyLabel,
                `${e.currentTarget.checked}`.toUpperCase(),
                mandatoryCheckBoxValidator(privacyPolicyEnabled)
              );
            }}
            value={CheckBoxMapper[state.values[privacyPolicyLabel]]}
            error={isError}
          />
        </div>
      }
      <div className='mt-6 negative-margin'>
        {authError && <ErrorBlock errorMessage={loginErrorMessage} classname={'py-40px'} />}
      </div>
      <div className={`${classNameSignInSubmitButton} flex justify-end mt-8 md:mt-12`}>
        <Button.Submit
          name='signIn'
          label='Sign In'
          size={'big'}
          disabled={!isValid || !!authError || isDisabled}
          className='min-w-full outline-auto-visible px-5 py-3 rounded bg-primary text-white on-hover-shadow'
        />
      </div>
      {showSubmitMessage &&
        <button
          name='Sign in with a different email'
          onClick={signInWithDifferentEmail}
          className='flex justify-center mt-8 font-size-13px text-primary bg-white cursor-pointer font-medium px-4 mx-auto py-1 rounded on-hover-shadow'
        >
          Sign in with a different email
        </button>
      }
    </Form>
  );
};
export default LoginForm;
