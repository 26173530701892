export function embedVeracast(appUrl: string,parentElId: string,data: Record<string,string>): void {
    var wrapper=document.createElement("div");
    wrapper.style.position="relative";
    wrapper.style.width="100%";
    wrapper.style.height="640px";

    var iframe=document.createElement("iframe");
    iframe.style.position="absolute";
    iframe.style.width="100%";
    iframe.style.height="100%";
    iframe.style.margin="0";
    iframe.style.padding="0";
    iframe.frameBorder="0";
    iframe.scrolling="no";
    iframe.setAttribute('allowFullScreen',true);
    var resizeId=(Math.random()+"").replace(".","");

    //set the src of the iframe to the webcast url
    //iframe.src=appUrl;
    iframe.name="veracastIframe"+resizeId;

    iframe.addEventListener("load",function(){
        iframe.contentWindow.postMessage("setResizeId","*");
    });

    var parent=parentElId ? document.getElementById(parentElId) : document.scripts[document.scripts.length - 1].parentElement;

    wrapper.appendChild(iframe);
    parent.appendChild(wrapper);

    if (!data) data={};

    //submit the form to the iframe
    dispenseForm(appUrl,data,iframe.name);

    parent.style.height="auto";

    window.addEventListener("message",function(event){
        if (event.source == iframe.contentWindow) sizeIframe(event.data);
    });

    return {
        iframe:iframe,
        refreshSize:function(){
            iframe.contentWindow.postMessage("refreshSize","*");
        }
    };

    function sizeIframe(sizeData){
        try {
            var size=JSON.parse(sizeData);
            
            //we use the height attribute set the new height of the iframe
            if (size.height) wrapper.style.height=size.height+"px";
        } catch (e){}
    }

    function dispenseForm(url,data,target){
        var target=target||"_blank";
    
        var newForm=document.createElement("form");
        newForm.setAttribute('method',"post");
        newForm.setAttribute('target',target);
        newForm.setAttribute('action',url);
    
        var keys=Object.keys(data);
    
        for (var i=0;i<keys.length;i++){
            var key=keys[i];
            newForm.appendChild(createHiddenInput(key,data[key]));
        }
    
        document.getElementsByTagName('body')[0].appendChild(newForm);
    
        newForm.submit();
    
        newForm.parentNode.removeChild(newForm);
    }
    
    function createHiddenInput(name,value){
        var newInput=document.createElement("input");
        newInput.setAttribute('type',"hidden");
        newInput.setAttribute('name',name);
        newInput.setAttribute('value',value);
    
        return newInput;
    }
}
